<template>
    <div class="zch-about">
        <Navigation />
        <div class="zch-about-container">
            <div class="zch-about-heading">
                <img src="../assets/logo.svg" alt="ZAKchat" />
                <div class="zch-about-heading-notice">About ZAKchat</div>
            </div>
            <div class="zch-about-content">
                <div class="zch-about-content-item-heading">What am I?</div>
                <div class="zch-about-content-item-text">
                    I’m ZAK your personal Zakat assistant. I'm a chatty robot that accurately works out your Zakat
                    through a one-to-one online chat.
                </div>
                <div class="zch-about-content-item-heading">So.. what’s the point of me?</div>
                <div class="zch-about-content-item-text">
                    Zakat has been around for about 1439 years, and back in the day, it was a lot easier to calculate
                    it.
                </div>
                <div class="zch-about-content-item-text">
                    I ran a survey with 1000 Zakat payers in 2020, and I found that 74% think they’ve previously
                    underpaid Zakat and 85% want help to make sense of it all!
                </div>
                <div class="zch-about-content-item-text">
                    Your finances are way more complicated than it has ever been before, so you need trustworthy
                    assistance to help you work out Zakat. You may have a pension scheme, businesses, investments, or
                    even cryptocurrency - so calculating it can be really confusing, especially with those basic Zakat
                    calculators out there 🙄
                </div>
                <div class="zch-about-content-item-heading">
                    Sooo… <em>(cough cough)</em> introducing me, ZAK, your personal Zakat assistant!
                </div>

                <div class="zch-about-content-item-text">
                    Using me is like using a Sat Nav. Sure, you may end up near your destination if you use an A to Z,
                    but it will take you a million times longer with countless wrong turns along the way.
                </div>

                <div class="zch-about-content-item-text">
                    My Zakat process is fully comprehensive, so whatever your financial circumstance, ZAKchat is ready
                    for you to use.
                </div>

                <div class="zch-about-content-item-text">
                    I’m here to help you work out your Zakat accurately and efficiently every single time, so you never
                    have to worry about mispaying Zakat, your third pillar of faith, ever again.
                </div>
                <div class="zch-about-content-item-text">Let's chat!</div>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "../layouts/Navigation";

export default {
    name: "AboutUs",
    components: {
        Navigation,
    },
};
</script>

<style lang="scss" scoped>
.zch-about {
    min-height: 100vh;
    width: 100%;
    padding: 24px;
    background: url(../assets/background-logo.png) no-repeat,
        radial-gradient(circle at 50% 50%, #fbfffe, #f6fffc 151%) no-repeat;
    background-size: contain;
    background-position-x: right;

    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 16.75%;
    }

    &-heading {
        margin-top: 50px;
        font-family: Muli, sans-serif;
        text-align: center;
        margin-bottom: 91px;

        & > img {
            max-height: 70px;
        }

        &-notice {
            font-size: 44px;
            font-weight: 800;
            text-align: center;
            color: #2b2b2b;
        }
    }

    &-content {
        &-item {
            &-heading {
                font-family: Muli, sans-serif;
                font-size: 20px;
                font-weight: 800;
                color: #2b2b2b;
                margin-left: -33px;
                padding-top: 40px;
            }

            &-subheading {
                font-family: Muli, sans-serif;
                font-size: 18px;
                font-weight: 700;
                color: #2b2b2b;
                margin-left: -20px;
                padding-top: 20px;
            }

            &-text {
                padding-top: 13px;
                font-family: Muli, sans-serif;
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.94;
                letter-spacing: normal;
                color: #6c6c6c;

                &-sub {
                    margin-left: 10px;
                    padding-top: 10px;
                }
            }
        }
    }
}
</style>
